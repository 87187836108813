{
  const handleChangeSearchData = () => {
    const searchValue = document.getElementById('storeSearch').value.toLowerCase();

    hideStores();
    searchStores(searchValue);
  }

  const hideStores = () => {
    const stores = Array.from(document.querySelectorAll('.item'));
    stores.forEach(store => store.classList.add('hidden'));
  }

  const searchStores = searchValue => {
    const stores = Array.from(document.querySelectorAll('.item'));

    const filteredStores = stores.filter(store => store.querySelector('.title').innerText.toLowerCase().includes(searchValue) || store.querySelector('.details').innerText.toLowerCase().includes(searchValue) || store.querySelector('.item__type').innerText.toLowerCase().includes(searchValue));

    filteredStores.forEach(store => store.classList.remove('hidden'));
  }

  const init = () => {
    const inputField = document.querySelector('.storeLocator__input');
    const searchButton = document.getElementById('storeSearchButton');

    if (inputField) {
      inputField.addEventListener(`keyup`, handleChangeSearchData);
      document.addEventListener(`focusout`, handleChangeSearchData);
    }
  };

  init();
}