{

  window.addEventListener('scroll', function(e) {

    const target = document.querySelectorAll('.scroll');


    var index = 0, length = target.length;
    for (index; index < length; index++) {
        const currentTarget = target[index];

        const vh = window.innerHeight;
        const targetY = currentTarget.getBoundingClientRect().top;
        const targetX = currentTarget.getBoundingClientRect().right;

        // var pos = window.pageYOffset * currentTarget.dataset.rate;
        var pos = (targetY * currentTarget.dataset.rate)/10;

        if (targetY <= vh) {

          // parallax effect
          if(currentTarget.dataset.direction === 'vertical' && this.window.innerWidth > 680) {
            currentTarget.style.transform = 'translate3d(0px,'+pos+'px, 0px)';
          } else {
              var posX = window.pageYOffset * currentTarget.dataset.ratex;
              var posY = window.pageYOffset * currentTarget.dataset.ratey;
              
              currentTarget.style.transform = 'translate3d('+posX+'px, '+posY+'px, 0px)';
          }

          // zoom on scroll
          if(currentTarget.dataset.effect === "zoom") {
            let docHeight = docHeight = document.documentElement.offsetHeight;
            const zoom = window.scrollY / ( docHeight - window.innerHeight ) + 1;

            currentTarget.style.transform = `scale(${zoom})`;
          }
        }
    }
  });
}