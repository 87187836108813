{

  const handleClickQuestion = e => {
    const question = e.currentTarget;

    const answer = question.nextElementSibling;

    answer.classList.toggle('hideText')
  }

  

  init = () => {
    const faqElements = Array.from( document.querySelectorAll(".faq__element"));

    faqElements.forEach(element => {
      let question = element.querySelector(".faq__question");
      question.addEventListener(`click`, handleClickQuestion);
    })
  }

  init();
}