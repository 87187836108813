{
  const prefix = 'SKL-'; // used to customize cookienaming per site

  let types = {
    functionalCookies: true,
    analyticsCookies: true,
    marketingCookies: true
  }

  const variableCookies = [];

  // {
  //   id: 'popup',
  //   name: `${prefix}Cadeaubon2022`,
  //   description: "Kortrijk cadeaubon seen",
  //   ttl: "90"
  // }

  const functionalCookies = [
    {
      name: `${prefix}Marketing`,
      description: "true",
      ttl: "90"
    },
    {
      name: `${prefix}Analytics`,
      description: "true",
      ttl: "90"
    },
    {
      name: `${prefix}Functional`,
      description: "true",
      ttl: "90"
    },
    {
      name: `${prefix}Cookieconsent`,
      description: "true",
      ttl: "90"
    }
  ];

  const analyticsCookies = [
    'google'
  ];

  const marketingCookies = [];

  let custom = false;

  const handleOpenCustomOptions = () => {
    // store if custom options is open or not
    custom = !custom;

    deselectDefaultChecks();

    if (custom) {
      document.getElementById('acceptSelection').classList.remove('hidden');
      document.getElementById('acceptAll').classList.add('hidden');
    } else {
      document.getElementById('acceptSelection').classList.add('hidden');
      document.getElementById('acceptAll').classList.remove('hidden');
    }

    // show/hide custom options
    const options = document.getElementById('customOptions');
    options.classList.toggle('cookiebanner__options--open');
  }

  const deselectDefaultChecks = () => {
    // deselect checkboxes on custom settings
    document.getElementById('marketing').checked = false;
    document.getElementById('analytics').checked = false;

    // set values to false so that cookies are not being set
    types.analyticsCookies = false;
    types.marketingCookies = false;

    // set actual cookie value to correct value
    functionalCookies[0].description = "false";
    functionalCookies[1].description = "false";
  }

  const handleCheckType = (e, type) => {
    // set the selected cookie types
    const value = e.currentTarget.checked;

    // set the type to the checked value
    types[type] = value;

    switch(type) {
      case 'functionalCookies':
        functionalCookies.find(obj => obj.name === `${prefix}Functional` ? obj.description = types['functionalCookies'].toString() : null);

      case 'analyticsCookies':
        functionalCookies.find(obj => obj.name === `${prefix}Analytics` ? obj.description = types['analyticsCookies'].toString() : null);

      case 'marketingCookies':
        functionalCookies.find(obj => obj.name === `${prefix}Marketing` ? obj.description = types['marketingCookies'].toString() : null);

      default:
        null;
    } 
  }

  const handleAcceptCookies = () => {
    // set selectedCookies
    setCookiesForType('functionalCookies');
    
    // update google analytics consent
    googleAnalyticsConsent(types['marketingCookies'], types['analyticsCookies']);

    // hide popup
    hideCookieBanner();
  }

  const hideCookieBanner = () => {
    document.querySelector(`.cookiebanner`).classList.add('hidden');
  }

  const googleAnalyticsConsent = (marketingConsent, analyticsConsent) => {
    gtag('consent', 'update', {
      'ad_storage': `${marketingConsent ? 'granted' : 'denied'}`,
      'analytics_storage': `${analyticsConsent ? 'granted' : 'denied'}`
    });
  }

  const setCookiesForType = type => {
    // loop cookies in type array and set those cookies
    eval(type).forEach(cookie => setCookie(cookie.name, cookie.description, cookie.ttl));
  }

  const getCookie = (name) => {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const eraseCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
  };

  const init = () => {
    // check for existing consent cookie
    const consentCookie = getCookie(`${prefix}Cookieconsent`);

    // if there is no consent cookie, show the cookie banner
    if (!consentCookie) {
      document.querySelector(`.cookiebanner`).classList.remove('hidden');
    } else {
      types['functionalCookies'] = (getCookie(`${prefix}Functional`) === 'true');
      types['analyticsCookies'] = (getCookie(`${prefix}Analytics`) === 'true');
      types['marketingCookies'] = (getCookie(`${prefix}Marketing`) === 'true');
    }

    // customize button
    const customizeButton = document.getElementById('customOptionsButton');

    if (customizeButton) {
      customizeButton.addEventListener(`click`, handleOpenCustomOptions);
    }

    // checkboxes functionality
    const functional = document.getElementById('functional');
    const analytics = document.getElementById('analytics');
    const marketing = document.getElementById('marketing');

    if (functional) {
      functional.addEventListener('change', e => handleCheckType(e, 'functionalCookies'));
      analytics.addEventListener('change', e => handleCheckType(e, 'analyticsCookies'));
      marketing.addEventListener('change', e => handleCheckType(e, 'marketingCookies'));
    }

    // accept cookies
    const accept = document.getElementById('acceptCookies');

    if (accept) {
      accept.addEventListener(`click`, () => handleAcceptCookies());
    }

    // decline cookies
    const decline = document.getElementById('declineCookies');

    if (decline) {
      decline.addEventListener(`click`, () => {
      document.querySelector(`.cookiebanner`).classList.add('hidden');
      })
    };

    // SET COOKIES FROM WEBSITE

    if (consentCookie && types['analyticsCookies']) {
      googleAnalyticsConsent(types['marketingCookies'], types['analyticsCookies']);
    }

    if (consentCookie && types['marketingCookies']) {
      googleAnalyticsConsent(types['marketingCookies'], types['analyticsCookies']);
    }

    if (consentCookie && types['functionalCookies']) {
      // set popup cookie (find data in cookies array, use data to find and set cookies)
      const cookieObject = variableCookies.find(obj => obj.id === 'popup');

      if (cookieObject) {
        const cookieName = cookieObject.name;
        const cookieDesc = cookieObject.description;
        const cookieTTL = cookieObject.ttl;
      }
      

      // const checkPopupCookie = getCookie(cookieObject.name);

      // if (checkPopupCookie) {
      //   document.querySelector(`.popup`).classList.add('hidden');
      // }

      // set cookie on close
      // document
      // .querySelector(`.close_popup`)
      // .addEventListener(`click`, function () {
      //   setCookie(cookieName, cookieDesc, cookieTTL);
      //   document.querySelector(`.popup`).classList.add('hidden');
      // });

      // set cookie on link click
      // document
      // .querySelector(`.popup-link`)
      // .addEventListener(`click`, function () {
      //   setCookie(cookieName, cookieDesc, 1);
      //   document.querySelector(`.popup`).classList.add('hidden');
      // });

    }

    // cookie related functionalities
    // document
    //   .querySelector(`.close_popup`)
    //   .addEventListener(`click`, function () {
    //     document.querySelector(`.popup`).classList.add('hidden');
    //   })

  }

  init();
}