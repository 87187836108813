{
  let hidden = true;

  const handleShowText = () => {
    hidden = !hidden;

    if (hidden) {
      document.querySelector('.about__content__content').classList.add('about__content__content--full');
    } else {
      document.querySelector('.about__content__content').classList.remove('about__content__content--full');
    }
  }

  const init = () => {
    const readmoreButton = document.getElementById('readmore');

    if(readmoreButton) {
      readmoreButton.addEventListener(`click`, handleShowText);
    }
  }

  init();
}