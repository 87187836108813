
{
  const domMap = document.getElementById('map');

  if (domMap) {
    mapboxgl.accessToken = 'pk.eyJ1Ijoia2FjaW0iLCJhIjoiY2t5aGJleGJrMjFkczJ3cDg0dzhiZDA0dSJ9.5dhpN-ldTWee9gnyhBB4-g';

    const stores = {
      'type': 'FeatureCollection',
      'features': []
      };
  

    // BUILD STORES ARRAY
    const domData = Array.from(document.querySelectorAll('.itemData'));

    domData.forEach(i => stores.features.push({
        'type': 'Feature',
        'geometry': {
        'type': 'Point',
        'coordinates': [i.dataset.lat, i.dataset.long]
        },
        'properties': {
        'type': i.dataset.type,
        'storeName': i.dataset.title,
        'address': i.dataset.street,
        'city': i.dataset.city,
        'country': i.dataset.country,
        'postalCode': i.dataset.zip,
        'contact': i.dataset.contact
        }
      }
    ))

    domData.forEach(i => i.remove());

  /**
  * Add the map to the page
  */

  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/light-v10',
    center: [3.259318721623492, 50.82667410418792],
    zoom: 12,
    scrollZoom: false
    });

  // Add zoom and rotation controls to the map.
  map.addControl(new mapboxgl.NavigationControl());
  
  
  /**
  * Assign a unique id to each store. You'll use this `id`
  * later to associate each point on the map with a listing
  * in the sidebar.
  */
  stores.features.forEach((store, i) => {
  store.properties.id = i;
  });
  
  /**
  * Wait until the map loads to make changes to the map.
  */
  map.on('load', () => {
  /**
  * This is where your '.addLayer()' used to be, instead
  * add only the source without styling a layer
  */
  map.addSource('places', {
  'type': 'geojson',
  'data': stores
  });
  
  /**
  * Add all the things to the page:
  * - The location listings on the side of the page
  * - The markers onto the map
  */
  buildLocationList(stores);
  addMarkers();
  });
  
  /**
  * Add a marker to the map for every store listing.
  **/
  function addMarkers() {
  /* For each feature in the GeoJSON object above: */
  for (const marker of stores.features) {
  /* Create a div element for the marker. */
  const el = document.createElement('div');
  /* Assign a unique `id` to the marker. */
  el.id = `marker-${marker.properties.id}`;
  /* Assign the `marker` class to each marker for styling. */
  el.className = 'marker';
  
  /**
  * Create a marker using the div element
  * defined above and add it to the map.
  **/
  new mapboxgl.Marker(el, { offset: [0, -23] })
  .setLngLat(marker.geometry.coordinates)
  .addTo(map);
  
  /**
  * Listen to the element and when it is clicked, do three things:
  * 1. Fly to the point
  * 2. Close all other popups and display popup for clicked store
  * 3. Highlight listing in sidebar (and remove highlight for all other listings)
  **/
  el.addEventListener('click', (e) => {
  /* Fly to the point */
  flyToStore(marker);
  /* Close all other popups and display popup for clicked store */
  createPopUp(marker);
  /* Highlight listing in sidebar */
  const activeItem = document.getElementsByClassName('active');
  e.stopPropagation();
  if (activeItem[0]) {
  activeItem[0].classList.remove('active');
  }
  const listing = document.getElementById(
  `listing-${marker.properties.id}`
  );
  listing.classList.add('active');
  });
  }
  }
  
  /**
  * Add a listing for each store to the sidebar.
  **/
    function buildLocationList(stores) {
    for (const store of stores.features) {
    /* Add a new listing section to the sidebar. */
    const listings = document.getElementById('listings');
    const listing = listings.appendChild(document.createElement('div'));
    /* Assign a unique `id` to the listing. */
    listing.id = `listing-${store.properties.id}`;
    /* Assign the `item` class to each listing for styling. */
    listing.className = 'item';

    const type = listing.appendChild(document.createElement('p'));
    type.classList.add('item__type')
    type.innerHTML = `${store.properties.type}`;
  
  /* Add the link to the individual listing created above. */
  const link = listing.appendChild(document.createElement('a'));
  link.href = '#locator';
  link.className = 'title';
  link.id = `link-${store.properties.id}`;
  link.innerHTML = `${store.properties.storeName}`;
  
  /* Add details to the individual listing. */
  const details = listing.appendChild(document.createElement('div'));
  details.classList.add('details');
  details.innerHTML = `${store.properties.address},<br/> ${store.properties.postalCode} ${store.properties.city} <br/><span class="item__contact">${store.properties.contact}</span>`;
  // if (store.properties.phone) {
  // details.innerHTML += ` &middot; ${store.properties.phoneFormatted}`;
  // }
  
  /**
  * Listen to the element and when it is clicked, do four things:
  * 1. Update the `currentFeature` to the store associated with the clicked link
  * 2. Fly to the point
  * 3. Close all other popups and display popup for clicked store
  * 4. Highlight listing in sidebar (and remove highlight for all other listings)
  **/
  link.addEventListener('click', function () {
  for (const feature of stores.features) {
  if (this.id === `link-${feature.properties.id}`) {
  flyToStore(feature);
  createPopUp(feature);
  }
  }
  const activeItem = document.getElementsByClassName('active');
  if (activeItem[0]) {
  activeItem[0].classList.remove('active');
  }
  this.parentNode.classList.add('active');
  });
  }
  }
  
  /**
  * Use Mapbox GL JS's `flyTo` to move the camera smoothly
  * a given center point.
  **/
  function flyToStore(currentFeature) {
  map.flyTo({
  center: currentFeature.geometry.coordinates,
  zoom: 13
  });
  }
  
  /**
  * Create a Mapbox GL JS `Popup`.
  **/
  function createPopUp(currentFeature) {
  const popUps = document.getElementsByClassName('mapboxgl-popup');
  if (popUps[0]) popUps[0].remove();
  const popup = new mapboxgl.Popup({ closeOnClick: false })
  .setLngLat(currentFeature.geometry.coordinates)
  .setHTML(
  `<h3>${currentFeature.properties.storeName}</h3><h4>${currentFeature.properties.address},<br/> ${currentFeature.properties.postalCode} ${currentFeature.properties.city}</h4>`
  )
  .addTo(map);
  }
  }
}