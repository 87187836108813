{
  const belgian = document.getElementById('belgian');
  const bioaroma = document.getElementById('bioaroma');
  const unique = document.getElementById('unique');

  const handleChangeContent = (type) => {
    const belgianContent = document.getElementById('belgian-text');
    const bioaromaContent = document.getElementById('bioaroma-text');
    const uniqueContent = document.getElementById('unique-text');

    switch(type){
      case 'belgian':
        belgianContent.classList.remove('hiddenAbout');

        bioaromaContent.classList.add('hiddenAbout');
        uniqueContent.classList.add('hiddenAbout');


        belgian.classList.add('about__content__button--active');

        bioaroma.classList.remove('about__content__button--active');
        unique.classList.remove('about__content__button--active');
        break;
      case 'bioaroma':
        bioaromaContent.classList.remove('hiddenAbout');

        belgianContent.classList.add('hiddenAbout');
        uniqueContent.classList.add('hiddenAbout');


        bioaroma.classList.add('about__content__button--active');

        belgian.classList.remove('about__content__button--active');
        unique.classList.remove('about__content__button--active');
        break;
      case 'unique':
        uniqueContent.classList.remove('hiddenAbout');

        belgianContent.classList.add('hiddenAbout');
        bioaromaContent.classList.add('hiddenAbout');


        unique.classList.add('about__content__button--active');

        bioaroma.classList.remove('about__content__button--active');
        belgian.classList.remove('about__content__button--active');
        break;
      default:
        break;
    };
  }; 

  const init = () => {
    if (belgian || bioaroma || unique) {

      belgian.addEventListener(`click`, () => handleChangeContent('belgian'));
      bioaroma.addEventListener(`click`, () => handleChangeContent('bioaroma'));
      unique.addEventListener(`click`, () => handleChangeContent('unique'));
    }
  };

  init();
}