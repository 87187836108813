import AOS from 'aos';

import './_cookies.js';
import './_menu.js';
import './_readMore.js';
import './_parallax.js';
import './_storeLocator.js';
import './_toggleMap.js';
import './_storeSearch.js';
import './_aboutContent.js';
import './_ajaxForm.js';
import './_faq.js';

{
  const init = () => {
    AOS.init({
      disable: '',
      easing: 'ease-in-out',
      duration: '650',
    });
  };

  init();
}
