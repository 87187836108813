{

  const handleSendMail = ev => {
    ev.preventDefault();

    var submit_url = "{{alias('@web')|e('js')}}";
    var message_success = "{{'Your message has been sent.'|t('contact-form')}}";
    var message_fail = "{{'Message could not be sent!'|t}}";

    // html elements
    var form_element = document.querySelector('.form');
    var form_button = form_element.querySelector('.me-button');
 
          var formData = new FormData(form_element);
          formData.append('action', 'contact-form/send');

          form_button.classList.add('is-loading');

        fetch(submit_url+'/', {
          method: 'POST', 
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        })
        .then(response => response.json())
        .then(data => {
            form_button.classList.remove('is-loading');
            if(data.success == true){
              form_element.reset();
              
              showSuccess();
            }else{
              showError();
              var allErrors = Array();
              for ( fieldErrors of Object.values(data.errors)){
                var concatedArray =  allErrors.concat(fieldErrors);
                allErrors = concatedArray;
              }
              var errorString = allErrors.join(' ');
            }
        })
        .catch(err => {
          showError();
        });
  }

  const showSuccess = () => {
    document.querySelector('.succes').classList.remove('hidden');
  }

  const showError = () => {
    document.querySelector('.error').classList.remove('hidden');
  }

  const init = () => {
    const form = document.getElementById("my-form");

    if (form) {
      form.addEventListener(`submit`, ev => handleSendMail(ev));
    }
  } 

  init();
}