{
  let showList = '';
  let showMap = '';

  const handleToggleMap = e => {
    document.querySelector('.me-button--active').classList.remove('me-button--active');
    e.currentTarget.classList.add('me-button--active');

    document.getElementById('list').classList.toggle('toggleView');
    document.getElementById('map').classList.toggle('toggleView');

  }

  const init = () => {
    showList = document.getElementById('showList');
    showMap = document.getElementById('showMap');

    if (showList) {
      showList.addEventListener(`click`, (e) => handleToggleMap(e));
      showMap.addEventListener(`click`, (e) => handleToggleMap(e));
    }
  }

  init();
}